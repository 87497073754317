@import './variables.scss';

.popup-component{
    position: absolute;
    width: 100vw;
    z-index: 10;
    height: 100%;
    min-height: fit-content;
    top: 0;
    left: 0;
    display: flex; 
    padding: 96px 0;
    overflow: scroll;
    box-sizing: border-box;
    background-color: #6D6D6D60;

    .popup-container{
        width: 60%;
        max-width: 832px;
        min-width: fit-content;
        height: fit-content;
        z-index: 1;
        background-color: $white-10;
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        padding: 32px;
        border-radius: 20px;
        &.download{
            h2{
                background: $green_gradient;
                 -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .size-selection-container .size-button.selected, .language-selection-container .language-button.selected{
                border-color: $green-10;
            }
        }
        &.order{
            h2{
                background: $teal_blue_gradient;
                 -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .size-selection-container .size-button.selected, .language-selection-container .language-button.selected{
                border-color: $blue-10;
            }
        }

        .mobile-resource-title{
            display: flex;
            flex-wrap: nowrap;
            color: $gray-10;
            margin-bottom: 10px;
            column-gap: 6px;
            padding: 0 20px;
        }

        .close-button{
            border: none;

            &:hover, &:focus{
                .close-path{
                    stroke: $gray-10;
                }
            }

            .close-path{
                stroke: $gray-12;
                stroke-width: 4;
                stroke-linecap: round;
            }
        }

        h2{
            align-self:  center;
            margin-bottom: 23px;
        }

        .size-selection-container{
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            column-gap: 40px;
            position: relative;
            padding-block: 16px !important;
            border-block: 1px solid $gray-9;
            justify-content: center;

            .size-button{
                width: fit-content;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;;
                padding:13px 13px 15px;
                border: 3px solid transparent;
                border-radius: 19px;
                text-align: left;
                max-width: 234px;
                box-sizing: content-box;
                row-gap: 8px;
                color: $black-10 !important;


                img{
                    width: auto;
                    height: 154px;
                    border-radius: 6px;
                    border:none
                }

                .size-description{
                    display: flex;
                    flex-direction: column;
                

                    h5{
                        align-self: flex-start;
                    }

                    .c2{
                        align-self: flex-start;
                        color: $gray-10;
                    }
                }
            }
        }

        .language-selection-container{
            display: flex;
            justify-content: center;
            column-gap: 24px;
            padding: 10px 0;
            border-bottom: 1px solid $gray-9;

            .language-button{
                display: flex;
                width: fit-content;
                padding: 11px 14px 15px;
                border: 3px solid transparent;
                border-radius: 8px;
                color: $black-10 !important;
            }
        }

        .preview-container{
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            flex-grow: 0;
            width: 100%;
            
            .preview-info{
                display: flex;
                flex-direction: row;
                column-gap: 21px;

                .preview-image{
                    height: 110px;
                    width: 110px;
                    position: relative;

                    img{
                        height: 100%;
                        width: 100%;
                    }
                }

                .preview-options{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    ul{
                        margin-block: 8px;
                        li {
                            color: $gray-13;
                        }
                    }
                }
            }
            .preview-order-container{
                display: flex;
                flex-direction: column;
                width: fit-content;
                margin-top: auto;
                margin-left: auto;

                .price-container{
                    .c2{
                        color: $gray-11;
                        line-height: 16px;
                    }
                }
            
                .preview-cart-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                }

                .add-to-cart-container{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        margin-top: 12px;
                        column-gap: 12px;
                        
                        .cart-button{
                            border-radius: 8px;
                            height: 48px;
                        }
                    }
            }

            .download{
                align-self: flex-end;
                margin-left: auto;
                height: 48px;
                padding: 0 13px;
            }
        }
    }

    @media screen and (min-width: 0) and (max-width: $tablet){
        position: absolute;
        height: fit-content;
        border-top: 1px solid $gray-13;
        inset: unset;
        box-sizing: unset;
        justify-content: unset;
        min-height: unset;
        overflow: hidden;
        padding: 0;
        bottom: 0;
        top: 0;
        height: 100vh;
        padding-bottom: env(safe-area-inset-bottom);

        .popup-container{
            margin: auto 0 0;
            height: fit-content;
            box-sizing: border-box;
            min-height: unset;
            max-height: unset;
            width: 100%;
            padding: 16px 0 0;
            position: relative;
            border-radius: 0;
            position: fixed;
            top: 100%;
            animation: popup-container .35s ease-in-out 0s 1 forwards;
            
            @keyframes popup-container {
                from{
                    transform: translateY(0);
                }
                to{
                    transform: translateY(-100%);
                
                }
            }

            &.closing{
                animation: close-container .35s ease-in-out 0s 1 forwards;
                @keyframes close-container {
                        from {
                            transform: translateY(-100%);
                        }
                
                        to {
                            transform: translateY(0);
                
                        }
                    }
            }
            .popup-header{
                margin-bottom: 12px;
            }

            .close-button{
                margin-left: auto;
                margin-right: 14px;
            }

            .size-selection-container{
                flex-direction: column;
                padding: 0 24px 14px;
                box-sizing: border-box;
                row-gap: 8px;

                .size-button{
                    width: 100%;
                    max-width: 100%;
                    flex-direction: row;
                    column-gap: 8px;
                    height: 84px;
                    padding: 6px;
                    box-sizing: border-box;


                    img{
                        height: 70px;
                    }

                    .size-description{
                        width: 100%;

                        .p2{
                            display: none;
                        }
                    }
                }
            }

            .preview-container{
                flex-wrap: wrap;
                padding: 0 20px ;
                box-sizing: border-box;
                padding: 20px 20px 30px;
                background-color: $white-13;
                bottom: 0;
                margin: 0;

                .preview-info{
                    flex-wrap: nowrap;
                    column-gap: 14px;
                    display: none;

                    .preview-options{
                        margin: 0;
                        
                        ul{
                            padding-inline-start: 20px;
                        }
                    }

                    .preview-image{
                        height: 118px;
                        width: 118px;
                        flex-shrink: 0;
                    }
                }

                .download{
                    margin: 0 auto;
                    align-self: center;
                }

                .preview-order-container{
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    text-align: unset;

                    
                    .price-container{
                        display: flex;
                        flex-direction: column;
                        flex-shrink: 1;
                        flex-grow: 1;
                        width: fit-content;
                        height: 100%;
                        
                        .c2{
                            line-height: 16px;
                        }
                    }

                    .add-to-cart-container{
                        flex-grow: 1;
                        margin-top: 0;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}